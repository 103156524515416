<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="1"></nav-header>

    <div class="app-container screenSharing">
      <div class="box">
        <!-- 步驟 7-8級 -->
        <div class="box-setp" v-if="['1', '2', '3', '4', '5', '6', '7', '8'].includes(entryLevel)">
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step1.png" alt="第一步">
            <p class="box-setp-div-p">耳機測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step2.png" alt="第二步">
            <p class="box-setp-div-p">麥克風測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step3.png" alt="第三步">
            <p class="box-setp-div-p">鏡頭測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step4.png" alt="第四步">
            <p class="box-setp-div-p">網速測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step5.png" alt="第五步">
            <p class="box-setp-div-p">螢幕分享</p>
          </div>
        </div>
        <!-- 步驟 1-6級 -->
        <!-- <div class="box-setp" v-if="['1', '2', '3', '4', '5', '6'].includes(entryLevel)">
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step1.png" alt="第一步">
            <p class="box-setp-div-p">耳機測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step2.png" alt="第二步">
            <p class="box-setp-div-p">鏡頭測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step3.png" alt="第三步">
            <p class="box-setp-div-p">網速測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/hardware/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/hardware/step4.png" alt="第四步">
            <p class="box-setp-div-p">螢幕分享</p>
          </div>
        </div> -->

        <!-- 內容 -->
        <div class="box-content">
          <!-- <div class="box-content-share" @click="screenShare">
            <img src="@/assets/image/icon/icon-shares.png" alt="" />
            分享螢幕畫面
          </div> -->
          <div class="box-content-left">
            <img src="@/assets/image/hardware/detection5.png" alt="螢幕分享測試">
          </div>
          <div class="box-content-right">
            <h1 class="box-content-right-h1">
              螢幕分享測試
            </h1>
            <h2 class="box-content-right-h2">
              最後，請點擊<span>「分享螢幕畫面」</span>以分享您的「整個螢幕畫面」
            </h2>
            <div class="box-content-right-div">
              <div class="player" v-show="agoraStatus == 2">
                <div class="player-screen" id="local-player1"></div>
                <!-- <div class="player-video" id="local-player2"></div> -->
              </div>
              <img class="img" v-show="agoraStatus != 2" src="@/assets/image/hardware/rectangle2.png" alt="">
            </div>
            <div class="box-content-right-button">
              <div class="button1" @click="openQuestion">有問題請點我</div>
              <div class="button2" @click="next">完成測試</div>
            </div>
          </div>
        </div>

        <!-- 提醒 -->
        <div class="box-remind">
          <img src="@/assets/image/hardware/remind.png" alt="提醒">
          {{ examinationRoomType == 3 ? '提醒：若無法排除問題，請加入line@帳號:@688qwier，由客服為您服務。' : '提醒：若無法排除問題，請撥打電話04-3707-7396 或 加入line@帳號:@688qwier，由客服為您服務。' }}
        </div>
      </div>
    </div>
    
    <!-- 問題彈窗 -->
    <detection-popup v-model="popupShow" type="screen_sharing"></detection-popup>
    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import { agroaRecordingStart } from '@/request/api'
import store from '@/store'
import { mapGetters } from "vuex"
import AgoraRTC from "agora-rtc-sdk-ng"
import DetectionPopup from './components/DetectionPopup.vue'
export default {
  name: 'ScreenSharing',
  components: { DetectionPopup },
  data() {
    return {
      // 分享彈窗
      shareShow: false,
      popupShow: false,

      // 是否打开了分享  0默认状态 1点击了分享  2正在分享
      agoraStatus: 0,
      // AgoraRTC 的实例
      agoraClient: {
        screenClient: null,
        videoClient: null
      },
      // 本地频道
      localTracks: {
        screenTrack: null,
        videoClient: null
      },
    }
  },
  computed: {
    ...mapGetters(['entryLevel', 'agoraAppid', 'agoraChannel', 'agoraUid', 'agoraCameraToken', 'agoraScreenToken', 'shareStatus', 'screenTrack', 'examinationRoomType']),
  },
  watch: {
    screenTrack(val) {
      if(val) {
        this.agoraStatus = 2
        // 播放本地视频曲目
        this.screenTrack.play("local-player1");
        // 监听屏幕分享停止
        let that = this
        this.screenTrack.on("track-ended", function(){
          that.agoraStatus = 0
          store.dispatch('agora/stopScreenShare')
          // 移除之前的标签
          document.getElementById(`local-player1`).innerHTML = ''
        })
      }
    }
  },
  methods: {
    // 有問題請點我
    openQuestion() {
      this.popupShow = true
    },
    // 完成測試
    next() {
      this.$router.push({
        path: '/notes'
      })
    },
    
    // 分享熒幕
    screenShare() {
      // 如果正在分享就不再执行分享
      if (this.agoraStatus != 0) return
      store.dispatch('agora/openScreenShare')

      // const that = this

      // let agoraScreenUid = Number('1' + this.agoraUid)
      // let agoraCameraUid = Number('2' + this.agoraUid)

      // // 屏幕分享
      // async function startScreenCall() {
      //   // 创建agora实例
      //   that.agoraClient.screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      //   // 创建用户链接
      //   await that.agoraClient.screenClient.join(that.agoraAppid, that.agoraChannel, that.agoraScreenToken, agoraScreenUid);
      //   // 创建视频
      //   that.localTracks.screenTrack = await AgoraRTC.createScreenVideoTrack();
      //   // 加入订阅
      //   await that.agoraClient.screenClient.publish(that.localTracks.screenTrack);
      //   // 播放本地视频曲目
      //   that.localTracks.screenTrack.play("local-player1");
      //   return that.agoraClient.screenClient;
      // }

      // // 视频直播
      // async function startVideoCall() {
      //   // 创建agora实例
      //   that.agoraClient.videoClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      //   // 创建用户链接
      //   await that.agoraClient.videoClient.join(that.agoraAppid, that.agoraChannel, that.agoraCameraToken, agoraCameraUid);
      //   // 创建视频
      //   that.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack();
      //   // 加入订阅
      //   await that.agoraClient.videoClient.publish(that.localTracks.videoTrack);
      //   // // 播放本地视频曲目
      //   // that.localTracks.videoTrack.play("local-player2");
      //   return that.agoraClient.videoClient;
      // }
      
      // Promise.all([startScreenCall(), startVideoCall()]).then(() => {
      //   this.agoraStatus = 2
      //   store.commit('agora/SET_SHARETYPE', 1)
      //   // 开启云端录制
      //   agroaRecordingStart({
      //     channel_name: this.agoraChannel,
      //     uid1: agoraScreenUid,
      //     uid2: agoraCameraUid,
      //   }).then(res => {
      //     store.commit('SET_AGORARESOURCEID', res.data.resourceId)
      //     store.commit('SET_AGORASID', res.data.sid)
      //   })
      // })
    },

    // // 停止分享
    // async closeRTC() {
    //   this.localTracks.screenTrack.stop();
    //   this.localTracks.screenTrack.close();
    //   this.localTracks.videoTrack.stop();
    //   this.localTracks.videoTrack.close();
    //   await this.agoraClient.screenClient.leave();
    //   await this.agoraClient.videoClient.leave();
    // }
  },
  // beforeDestroy() {
  //   if (this.agoraStatus != 0) {
  //     this.closeRTC()
  //   }
  // }
}
</script>

<style lang="less" scoped>
.screenSharing {
  .box {
    margin: 20px auto;
    width: 1320px;
    .box-setp {
      display: flex;
      justify-content: center;
      .box-setp-div {
        text-align: center;
        .box-setp-div-img {
          margin-bottom: 11px;
          width: 60px;
          height: 60px;
        }
        .box-setp-div-p {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #989898;
        }
      }
      .box-setp-arrow {
        margin: 24px 0 0;
        padding: 0 60px;
        position: relative;
        width: 6px;
        height: 8px;
        .box-setp-arrow-img {
          width: 100%;
          height: 100%;
        }
        .box-setp-arrow-left {
          position: absolute;
          top: 6px;
          left: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
        .box-setp-arrow-right {
          position: absolute;
          top: 6px;
          right: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
      }
      .active {
        .box-setp-div-p {
          color: #0064D9;
        }
        .box-setp-arrow-left {
          border: 1px dashed #0665D8;
        }
        .box-setp-arrow-right {
          border: 1px dashed #0665D8;
        }
      }
    }

    .box-content {
      margin-top: 20px;
      padding: 50px 60px 50px 140px;
      width: calc(100% - 200px);
      height: 500px;
      min-height: calc(100vh - 444px);
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      position: relative;
      .box-content-share {
        cursor: pointer;
        user-select: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 136px;
        height: 36px;
        background: #5FC59F;
        border-radius: 0px 8px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 32px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 8px;
          width: 18px;
          height: 18px;
        }
      }
      .box-content-left {
        margin-right: 82px;
        width: 438px;
        height: 320px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-content-right {
        width: calc(100% - 520px);
        height: 100%;
        .box-content-right-h1 {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #333333;
        }
        .box-content-right-h2 {
          margin: 20px 0;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #333333;
          span {
            color: #0C4FA2;
          }
        }
        .box-content-right-div {
          width: 270px;
          height: 150px;
          border-radius: 8px;
          overflow: hidden;
          .img {
            width: 100%;
            height: 100%;
          }
          .player {
            width: 100%;
            height: 100%;
            position: relative;
            .player-screen {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
        .box-content-right-button {
          margin-top: 40px;
          user-select: none;
          display: flex;
          align-items: center;
          .button1 {
            cursor: pointer;
            margin-right: 22px;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #FFFFFF;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #0C4FA2;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .button2 {
            cursor: pointer;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #0C4FA2;
            box-shadow: 0px 4px 8px rgba(0, 73, 207, 0.3);
            border-radius: 8px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .box-remind {
      margin-top: 20px;
      width: 100%;
      height: 48px;
      background: #FDF3EC;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #DA6B29;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>